import React from 'react';
import ProductItem from './ProductItem';

const products = [
    { id: 1, name: 'Product 1', description: 'Description 1', price: 100 },
    { id: 2, name: 'Product 2', description: 'Description 2', price: 200 },
    // Додайте більше товарів тут
];

const ProductList = () => (
    <div>
        {products.map((product) => (
            <ProductItem key={product.id} product={product} />
        ))}
    </div>
);

export default ProductList