// src/components/ProductItem.js
import React from 'react';

const ProductItem = ({ product }) => (
    <div>
        <h2>{product.name}</h2>
        <p>{product.description}</p>
        <p>${product.price}</p>
    </div>
);

export default ProductItem;