import React from 'react';
import ProductList from './components/ProductList';
import './App.css';

const App = () => (
  <div className='site'>
    <div className='header__container'>
      <header className="header">
        <div className="logo-container">
          <img src="./images/LOGO_white.svg" alt="Logo" className="logo" />
          <h1 className="shop-name">disquare</h1>
        </div>
        <div className="cart"> <img src="./svg/Card.svg" alt="cart" className="cart" /></div>
      </header>
    </div>
    <div className="container">

      <aside className="sidebar">
        <h2>Categories</h2>
        <ul>
          <li>Category 1</li>
          <li>Category 2</li>
          <li>Category 3</li>
        </ul>
      </aside>
      <main className="main-content">
        <ProductList />
      </main>
    </div>
  </div>
);

export default App;